import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Popup } from '../../../lib/custom-ui';

import { useField, useSteps } from '../../../hooks';
import UserItem from './UserItem';
import DeleteStep from '../../DeleteStep';

import styles from './EditStep.module.scss';
import ActionsStep from '../ActionsStep';

const StepTypes = {
  EDIT_PERMISSIONS: 'EDIT_PERMISSIONS',
  DELETE: 'DELETE',
};

const EditStep = React.memo(
  ({
    users,
    permissionsSelectStep,
    leaveButtonContent,
    leaveConfirmationTitle,
    leaveConfirmationContent,
    leaveConfirmationButtonContent,
    deleteButtonContent,
    deleteConfirmationTitle,
    deleteConfirmationContent,
    deleteConfirmationButtonContent,
    canLeaveIfLast,
    canEdit,
    title,
    onUpdate,
    onDelete,
    onClose,
  }) => {
    const [t] = useTranslation();
    const [step, openStep, handleBack] = useSteps();
    const [search, handleSearchChange] = useField('');
    const [membership, setMembership] = useState();

    const cleanSearch = useMemo(() => search.trim().toLowerCase(), [search]);

    const filteredUsers = useMemo(
      () =>
        users.filter(
          (user) =>
            user.user.email.includes(cleanSearch) ||
            user.user.name.toLowerCase().includes(cleanSearch) ||
            (user.user.username && user.user.username.includes(cleanSearch)),
        ),
      [users, cleanSearch],
    );

    const searchField = useRef(null);

    const handleUserSelect = useCallback(
      (id) => {
        openStep(StepTypes.EDIT_PERMISSIONS, {
          userId: id,
        });
      },
      [openStep],
    );

    useEffect(() => {
      searchField.current.focus({
        preventScroll: true,
      });
    }, []);

    if (step) {
      switch (step.type) {
        case StepTypes.EDIT_PERMISSIONS: {
          const currentUser = users.find((user) => user.user.id === step.params.userId);

          if (currentUser) {
            const PermissionsSelectStep = permissionsSelectStep;

            return (
              /*
              <ActionsStep
                membership={membership}

              />
              */
              <ActionsStep
                membership={membership}
                permissionsSelectStep={PermissionsSelectStep}
                leaveButtonContent={leaveButtonContent}
                leaveConfirmationTitle={leaveConfirmationTitle}
                leaveConfirmationContent={leaveConfirmationContent}
                leaveConfirmationButtonContent={leaveConfirmationButtonContent}
                deleteButtonContent={deleteButtonContent}
                deleteConfirmationTitle={deleteConfirmationTitle}
                deleteConfirmationContent={deleteConfirmationContent}
                deleteConfirmationButtonContent={deleteConfirmationButtonContent}
                canEdit={canEdit}
                canLeave={users.length > 1 || canLeaveIfLast}
                onUpdate={(data) => onUpdate(membership.id, data)}
                onDelete={() => onDelete(membership.id)}
                onClose={onClose}
              />
              /*
              <PermissionsSelectStep
                defaultData={pick(membership, ['role', 'canComment'])}
                title="common.editPermissions"
                buttonContent="action.save"
                onSelect={handleRoleSelect}
                onBack={handleBack}
                onClose={onClose}
              />
              */
            );
          }
          break;
        }
        case StepTypes.DELETE:
          return (
            <DeleteStep
              title={membership.user.isCurrent ? leaveConfirmationTitle : deleteConfirmationTitle}
              content={
                membership.user.isCurrent ? leaveConfirmationContent : deleteConfirmationContent
              }
              buttonContent={
                membership.user.isCurrent
                  ? leaveConfirmationButtonContent
                  : deleteConfirmationButtonContent
              }
              onConfirm={onDelete}
              onBack={handleBack}
            />
          );
        default:
      }
    }

    return (
      <>
        <Popup.Header>
          {t(title, {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <Input
            fluid
            ref={searchField}
            value={search}
            placeholder={t('common.searchUsers')}
            icon="search"
            onChange={handleSearchChange}
          />
          {filteredUsers.length > 0 && (
            <div className={styles.users}>
              {filteredUsers.map((item) => (
                <UserItem
                  key={item.user.id}
                  name={item.user.name}
                  avatarUrl={item.user.avatarUrl}
                  isActive={false}
                  onSelect={() => {
                    setMembership(item);
                    handleUserSelect(item.user.id);
                  }}
                />
              ))}
            </div>
          )}
        </Popup.Content>
      </>
    );
  },
);

EditStep.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  users: PropTypes.array.isRequired,
  /* eslint-disable react/forbid-prop-types */
  permissionsSelectStep: PropTypes.elementType,
  leaveButtonContent: PropTypes.string,
  leaveConfirmationTitle: PropTypes.string,
  leaveConfirmationContent: PropTypes.string,
  leaveConfirmationButtonContent: PropTypes.string,
  deleteButtonContent: PropTypes.string,
  deleteConfirmationTitle: PropTypes.string,
  deleteConfirmationContent: PropTypes.string,
  deleteConfirmationButtonContent: PropTypes.string,
  canLeaveIfLast: PropTypes.bool,
  canEdit: PropTypes.bool,
  title: PropTypes.string,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditStep.defaultProps = {
  permissionsSelectStep: undefined,
  leaveButtonContent: 'action.leaveBoard',
  leaveConfirmationTitle: 'common.leaveBoard',
  leaveConfirmationContent: 'common.areYouSureYouWantToLeaveBoard',
  leaveConfirmationButtonContent: 'action.leaveBoard',
  deleteButtonContent: 'action.removeFromBoard',
  deleteConfirmationTitle: 'common.removeMember',
  deleteConfirmationContent: 'common.areYouSureYouWantToRemoveThisMemberFromBoard',
  deleteConfirmationButtonContent: 'action.removeMember',
  title: 'common.editMember',
  onUpdate: undefined,
  canLeaveIfLast: true,
  canEdit: true,
};

export default EditStep;
